/* * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;

}*/
.Mui-selected {
  background-color: #8ebbff;
}
* {
  --joy-palette-action-selectedOpacity: 0.5;
  --joy-palette-background-surface: #000010;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.headcontainer {
  --joy-fontFamily-body: "Montserrat", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  height: 100vh;
}
input::placeholder {
  color: red;
}
.formcontainer {
  width: 80vw;
  height: 75vh;
  overflow-y: scroll;
  box-shadow: none !important;
}

.Name,
.yearno {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.register {
  width: 100px;
  height: 40px;
}

.main-form {
  display: flex;
}

.formsheet {
  flex-basis: 80%;
}

.loginimage {
  width: 40%;
}
.sel {
  width: 30%;
}

@media screen and (max-width: 600px) {
  .formcontainer {
    width: 90vw;
  }
  .loginimage {
    display: none;
  }
  .formcheck {
    flex-direction: column;
  }
  .check {
    margin: 0.5rem;
  }
  .sel {
    width: 100%;
  }
}
