@font-face {
  font-family: "x";
  src: url("./Azonix.otf");
  font-weight: normal;
  font-style: normal;
}

ol {
  width: 100%;
}

h3 {
  margin: 1rem 0;
}

.overlay {
  width: 100%;
  height: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  // background-color: rgba(25, 24, 24, 0.657);
  z-index: 2;
  backdrop-filter: blur(5px);
}

// @keyframes animate {
//   0% {
//     transform: scaleY(1) translateY(-60%);
//     opacity: 0;
//   }

//   100% {
//     transform: scaleY(1);
//     opacity: 1;
//   }
// }

.modal {
  overflow-y: scroll;
  position: absolute;
  // animation: animate 1s;
  max-width: 768px;
  height: 40%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(0, 2, 36, 0.9);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transform: translateY(-60%);
  z-index: 3;

  // box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.75);
  padding: 2rem;
  border-radius: 10px;
  margin-top: 3rem;

  .close-btn {
    font-size: 2rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  .title {
    font-family: "x";
    margin-top: 1rem;
    line-height: 1.2;
  }

  .desc {
    text-align: justify;
    margin: 1rem 0;
  }
  .topic {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .grid {
    margin: 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    .item {
      border-radius: 10px;
      border: 1px solid white;
      padding: 1rem;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }

  ol {
    li {
      margin: 0.5rem 1rem;

      span {
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 760px) {
  .modal {
    width: 90%;
    height: 70vh;
    margin-top: 0;
    margin-bottom: 2rem;

    .grid {
      grid-template-columns: repeat(2, 1fr);
      margin: 1.5rem 0;
    }

    .close-btn {
      top: 1.5rem;
      right: 1rem;
    }

    .rules,
    .round1,
    .round2,
    .round3,
    .round4,
    .judging {
      margin-bottom: 1rem;
    }
  }
}
