/* :root {
    --color: #3c3163;
} */

/* .eventcard {
    font-family: "poppins", sans-serif;
    --bg-filter-opacity: 0.5;
    --bg-img: url(https://codetheweb.blog/assets/img/posts/html-syntax/cover.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, var(--bg-filter-opacity)), rgba(0, 0, 0, var(--bg-filter-opacity))), var(--bg-img);
    height: 15em;
    width: 12em;
    font-size: 1.5em;
    color: white;
    border-radius: 1em;
    padding: 1em;

    display: flex;
    align-items: flex-end;

    background-size: cover;
    background-position: center;

    box-shadow: 0 0 2em -1em black;
    transition: all, 0.5s;
    position: relative;
    overflow: hidden;
    text-decoration: none;
} */

.eventcard:hover {
  /* transform: rotate(0); */
  transform: scale(1.1);
}

.eventcard h1 {
  margin-bottom: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.5em;
  line-height: 1.2em;
}

.eventcard p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.7em;
  margin-bottom: 1em;
}

.eventcard .tags {
  display: flex;
}

.eventcard .tags .tag {
  font-size: 0.75em;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, 0.5s;
}

.eventcard:hover .tags .tag {
  background: rgba(255, 255, 255, 0.75);
  color: #282828;
}

@media screen and (max-width: 600px) {
  .eventcard .tags .tag {
    background: rgba(255, 255, 255, 0.65);
    color: #282828;
  }
}

.eventcard .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: 0.8;
}

.eventcard:before,
.eventcard:after {
  content: "";
  transform: scale(0);
  transform-origin: top left;
  border-radius: 50%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, 0.5s;
  transition-timing-function: ease-in-out;
}

.eventcard:hover:before,
.eventeventcard:hover:after {
  transform: scale(1);
}

@media only screen and (max-width: 600px) {
  .eventcard:hover {
    transform: none;
  }
}
