.rbtn {
  margin-top: 1rem;
  position: relative;
  width: 200px;
  height: 60px;
  color: white !important;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  border-top: 0.5px solid rgba(255, 255, 255, 0.35);
  border-left: 0.5px solid rgba(255, 255, 255, 0.35);
  padding-left: 40px;
  transition: 0.5s;
  overflow: hidden;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    padding-left: 0;
    padding-right: 40px;
    color: white;
  }

  span {
    position: absolute;
    left: 5px;
    width: 50px;
    height: 50px;
    background-color: #ff3c78;
    border-radius: 50%;
    transition: 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
  }

  &::after {
    content: "";
    position: absolute;
    width: 80px;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.25);
    transform: translateX(-170px) skewX(30deg);
    transition: 0.75s ease-in-out;
  }

  &:hover span {
    left: calc(100% - 55px);
  }

  &:hover::after {
    transform: translateX(170px) skewX(30deg);
  }
}
