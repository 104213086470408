.footer {
  width: 100%;
  .container {
    width: 85%;
    margin-inline: auto;
  }
  .flex {
    font-size: 14px;
    display: flex;
    justify-content: space-around;
    gap: 3rem;
    margin: 4rem;
    margin-inline: auto;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .block {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;

      flex: 100;
      .heading {
        margin-bottom: 1.5rem;
      }

      .links {
        margin: 0.4rem 0;
        text-decoration: none;
        color: white;
      }
    }
  }
  .block .social-links {
    display: flex;
    flex-wrap: wrap;
  }
  .block .social-links a {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 2.5rem;
    width: 2.5rem;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
  }
  .block .social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
  }
  .mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 300px;
  }

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 125%;
    height: 300px;
  }

  .gmap_iframe {
    height: 300px !important;
  }

  .copyright {
    background-color: rgba(0, 0, 0, 0.824);
    padding: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desc {
    margin: 3rem 0;
    line-height: 1.5;
    @media screen and (max-width: 600px) {
      margin: 1rem 0;
    }
  }
  .address {
    margin: 1rem 0;
    font-weight: 600;
  }  
}