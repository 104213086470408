@font-face {
  font-family: "Azonix";
  src: url("./components/Events/styles/Azonix.otf");
  font-weight: normal;
  font-style: normal;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px 000010 inset !important;
  -webkit-text-fill-color: white !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}

@media screen and (max-width: 600px) {
  html {
    scroll-padding-top: 90px;
  }
  .img {
    margin-top: -70vh;
  }
}
@media screen and (min-width: 600px) {
  .img {
    margin-top: -60vh;
  }
}

body {
  background-image: url("../src/assets/green2.webp");
  background-size: 500%;
  background-color:  rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  /* background-image: linear-gradient(
    #061018,
    #050e17,
    #040c12,
    #020910,
    #00060a
  );
  color: #fff;
  font-family: Montserrat, sans-serif;
  background-attachment: fixed;*/
} 

.lander-text {
  font-family: "popines";
}

.gmap_iframe {
  height: 350px !important;
}

.mapouter {
  margin-inline: auto;
  position: relative;
  text-align: right;
  max-width: 700px;
  height: 350px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  max-width: 700px;
  height: 350px;
}

.img {
  /* background-image: url("../src/assets/earth.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  /* margin-top: -60vh; */
  min-width: 100%;
  will-change: transform;
  min-height: 100vh;
}

/* @media screen and(max-width:600px) {
 
} */

section {
  height: 200vh;
}

/* partical */

.particles {
  position: absolute;
  z-index: -10000;
}

progress

.scrollprogress {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  height: 4px;
  background: #005f10;
  background: rgb(14, 5, 5);
  transform-origin: 0%;
  z-index: 10000000;
  border-radius: 100px;
}

/* register now btn */
.span_btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.span_btn::after {
  content: "\00bb";
  position: absolute;
  color: rgb(0, 0, 0);
  opacity: 0;

  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 40px;
}

.button:hover span::after {
  opacity: 1;
  right: 0;
}

.countdown {
  width: 45%;
  font-family: unset;
}

/* ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

.flip-countdown-title {
  font-family: "Montserrat", sans-serif;
}

.MainHeaderDate {
  padding-top: 20px;
}